/*
 * @Date: 2022-04-07 19:28:41
 * @LastEditors: pengff
 * @LastEditTime: 2022-06-24 12:06:49
 * @FilePath: \front-public\packages\work-platform\src\common\rsg.ts
 */
import { JSEncrypt } from 'jsencrypt';
/**
 * 对目标字符串进行RSA加密
 * @param value 目标字符串
 * @param publicKey 公钥内容
 * @returns encryptedStr 加密结果
 */
export const RsaEncrypt = (value: string, publicKey: string): string => {
  const encrypt: any = new JSEncrypt();
  if ((window as any).Base64?.noConflict) {
    (window as any).Base64.noConflict();
  }
  encrypt.setPublicKey(publicKey);
  const encryptedStr: string = encrypt.encrypt(value);
  return encryptedStr;
};

export const RsaDecrypt = (value: string, privateKey: string): string => {
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(privateKey);
  var decryptMsg = decrypt.decrypt(value);
  return decryptMsg || '';
};
